<template>
  <div>
    <a-modal
      title="编辑模板"
      :width="500"
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          保存
        </a-button>
      </template>
      <div>
        <a-spin :spinning="false">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-form-model-item label="模板ID" required>
              <a-input
                style="width: 300px"
                placeholder="请输入模板ID"
                v-model="form.sms_id"
              />
            </a-form-model-item>

            <a-form-model-item label="模板内容" required>
              <a-textarea
                style="width: 300px"
                placeholder="请输入模板内容"
                :rows="3"
                v-model="form.tpl"
              />
            </a-form-model-item>

            <a-form-model-item label="发送的英文KEY" required>
              <a-input
                style="width: 300px"
                placeholder="请输入发送的英文KEY"
                :change="check()"
                v-model="form.key"
              />
            </a-form-model-item>

            <a-form-model-item label="作用" required>
              <a-input
                style="width: 300px"
                placeholder="请输入作用"
                v-model="form.effect"
              />
            </a-form-model-item>
          </a-form-model>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tpl_id: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.tpl_id != 0) {
      this.loaddata();
    }
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      form: {
        sms_id: "",
        tpl: "",
        key: "",
        effect: "",
      },
    };
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/showSmsTpl", {
          tpl_id: this.tpl_id,
        })
        .then((res) => {
          if (res.detail) {
            this.form = res.detail;
          }
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          this.$emit("cancel");
        });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/admin/saveSmsTpl", {
          tpl_id: this.tpl_id,
          sms_id: this.form.sms_id,
          tpl: this.form.tpl,
          key: this.form.key,
          effect: this.form.effect,
        })
        .then((res) => {
          this.$message.success("保存成功", 1, () => {
            this.confirmLoading = false;
            this.$emit("ok");
          });
        })
        .catch((res) => {
          console.log(res);
          this.confirmLoading = false;
        });
    },
    check() {
      this.form.key = this.form.key.replace(/[^a-zA-Z]/g, "");
    },
  },
};
</script>

<style></style>
